import React, {useState} from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "./container";
import { device } from "../styles/mediaQueries";
import ExhibitionsWrapper from "../components/exhibitions/exhibition-wrapper";
import styled from "styled-components";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import * as classes from "./exhibition.module.css"

const GalleryGrid = styled.div` 
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: masonry;
  gap: 2rem;
  @media ${device.mediaMinMedium} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${device.mediaMinLarge} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Exhibition = (props) => {
  const { title } = props;
  const galleryImages = props.exhibitionGallery.images;

  const [open, setOpen] = useState({
    isOpen: false,
    img: null
  });

  const slides = galleryImages.map(img => {
    return (
      img.asset.url
    )
  })

  return (
    <ExhibitionsWrapper>
      <Container>
        <h1 style={{margin: '0 0 0.5rem 0', padding: '0'}}>{title}</h1>
        <h3 style={{margin: '0 0 0.5rem 0', padding: '0'}}>{props.exhibitionDate}</h3>
        <GalleryGrid>
          {galleryImages.map(image => {
            console.log(image)
            return (
              <div 
                onClick={() => setOpen({isOpen: true, img: image.asset.url})}
                key={image.asset.id}
                style={{position: 'relative', cursor:'pointer'}}
                className={`${image.fullSize ? classes.fullSize : ""}`}
              >
                <GatsbyImage 
                  image={image.asset.gatsbyImageData} 
                  alt={image.alt} 
                  placeholder="blurred"
                  quality="50"
                />
              </div>
            )
          })
          }
        </GalleryGrid>
        <Lightbox
          open={open.isOpen}
          close={() => setOpen({isOpen: false, img: null})}
          slides={[
            {
              src: open.img,
              alt: "image",
              // width: 3840,
              // height: 2560,
            },
          ]}
          render={{
            iconPrev: () => null,
            iconNext: () => null,
          }}
        />
      </Container>
    </ExhibitionsWrapper> 
  );
}

export default Exhibition;
