import React from "react";
import styled from "styled-components";
import { device } from '../../styles/mediaQueries'

const StyledExhibitionsWrapper = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  gap: 2rem;
  padding: 6rem 2rem 2rem;
  flex-direction: column-reverse;
  @media ${device.mediaMinLarge} {
    flex-direction: row;
    padding: 2rem;
    /* height: 100vh; */
    gap: 4rem;
    padding: 6rem 1rem 1rem;
  }
`

const ExhibitionsWrapper = ({style ,children}) => {
  return ( 
    <StyledExhibitionsWrapper style={style}>{children}</StyledExhibitionsWrapper>
   );
}
 
export default ExhibitionsWrapper;