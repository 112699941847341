import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Exhibition from "../components/exhibition";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query ExhibitionTemplateQuery($id: String!) {
    exhibition: sanityExhibitions(id: { eq: $id }) {
        title
        exhibitionDate
        slug {
          current
        }
        exhibitionGallery {
          images {
            alt
            fullSize
            asset {
              id
              gatsbyImageData
              url
            }
          }
        }
      }
    }
`;

const ExhibitionTemplate = props => {
  const { data, errors } = props;
  const exhibition = data && data.exhibition;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {exhibition && <SEO title={exhibition.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {exhibition && <Exhibition {...exhibition} />}
    </Layout>
  );
};

export default ExhibitionTemplate;
